import { h } from "preact";

import "./App.scss";
import Hexagons from "./components/Hexagons";

// import fontawesome


const BASE_DOMAIN = process.env.BASE_DOMAIN;

const App = () => {
    return (
        <div className="app">
            <a href={`https://v1.${BASE_DOMAIN}`} className="v-btn prev-v-btn">
                <i class="fa-solid fa-caret-left"></i>
                V1
            </a>
            {/* <a href={`https://v3.${BASE_DOMAIN}`} className="v-btn next-v-btn">
                V3
                <i class="fa-solid fa-caret-right"></i>
            </a> */}
            {/* <h1 className="title">Crepi</h1> */}
            <Hexagons />
        </div>
    );
};

export default App;