import { access } from "fs";
import { cloneElement, h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { title } from "process";

import "./Hexagons.scss";


let img_mapacrepi = require('../assets/images/instagram_imgs/2022-04-04_2809245900745778903.jpg');
let img_itto = require('../assets/images/instagram_imgs/2021-12-15_2728960689399914984.jpg');
let img_kirby = require('../assets/images/instagram_imgs/2022-03-26_2802616199335913232.jpg');
let img_baji = require('../assets/images/instagram_imgs/2021-12-21_2733259337356993132.jpg');
let img_bunny = require('../assets/images/instagram_imgs/2021-01-01_2476744924208149552.jpg');
let img_crepitas = require('../assets/images/instagram_imgs/2021-09-24_2670008736808832985.jpg');
let img_lol = require('../assets/images/instagram_imgs/2021-01-26_2495455147164842479.jpg');

// function* randomImageGenerator() {
//     const random_order = Object.keys(ig_imgs).sort(() => (Math.random() > 0.5 ? 1 : -1));
//     let i = 0;
//     while (true) {
//         yield random_order[i % random_order.length];
//         i = (i + 1) % random_order.length;
//     }
// }

// function* orderedImageGenerator() {
//     const order = Object.keys(ig_imgs);
//     let i = 0;
//     while (true) {
//         yield order[i % order.length];
//         i = (i + 1) % order.length;
//     }
// }

// const imageKeyGen = orderedImageGenerator();
// const imageKeyGen = randomImageGenerator();


const Hexagons = () => {
    const [itemsState, setItemsState] = useState([
        {
            img: img_itto, clicked: false,
            target: "discord", icon: "fa-brands fa-discord", title: "Discord", url: "https://discord.gg/XBPKXgAhFy",
            description: "Conviértete en una frutita en mi Discord",
        },
        {
            img: img_kirby, clicked: false,
            target: "tiktok", icon: "fa-brands fa-tiktok", title: "TikTok", url: "https://www.tiktok.com/@crezpy",
            description: "No bailo pero hago dibujitos, todos pequeños todos bonitos en TikTok",
        },
        {
            img: img_baji, clicked: false,
            target: "twitter", icon: "fa-brands fa-twitter", title: "Twitter", url: "https://twitter.com/crezpy_",
            description: "Léeme un rato en Twitter  #NoMeFunen",
        },
        {
            img: img_mapacrepi, clicked: false,
            target: "twitch", icon: "fa-brands fa-twitch", title: "Twitch", url: "https://www.twitch.tv/crepiy_y",
            description: "Platica conmigo mientras juego o dibujo en Twitch",
        },
        {
            img: img_bunny, clicked: false,
            target: "instagram", icon: "fa-brands fa-instagram", title: "Instagram", url: "https://www.instagram.com/crepiy_y/",
            description: "Checa mis ilustraciones en Instagram",
        },
        {
            img: img_crepitas, clicked: false,
            target: "youtube", icon: "fa-brands fa-youtube", title: "Youtube", url: "https://www.youtube.com/@crezpydejjazpy4226",
            description: "Conoce a la Crepi del pasado",
        },
        {
            img: img_lol, clicked: false,
            target: "amazon", icon: "fa-brands fa-amazon", title: "Wishlist", url: "https://www.amazon.com.mx/hz/wishlist/ls/3MR0SK6DG5A31",
            description: "Hazme feliz con un regalito de Amazon",
        },
    ]);

    const [isFloating, setIsFloating] = useState(false);

    useEffect(() => {
        const areClicked = itemsState.map((val) => val.clicked).reduce((acc, val) => acc || val);
        setIsFloating(!areClicked);
    }, [itemsState]);

    const handleClick = (i: number) => {
        const newItemsState = [...itemsState];
        newItemsState[i].clicked = !newItemsState[i].clicked;
        setItemsState(newItemsState);
    };

    // const numItems = 7;
    // const imgUrls = Array.from(Array(numItems).keys()).map(() => {
    //     const newImageKey = imageKeyGen.next().value as string;
    //     return (ig_imgs[newImageKey].webp || ig_imgs[newImageKey].png || ig_imgs[newImageKey].jpg) as string;
    // });
    // console.log(Array(numItems));
    // console.log(imgUrls);
    // console.log(itemsState);

    return (
        <div className="gallery-wrapper">
            <div className={`gallery ${isFloating ? " floating" : ""}`}>
                {itemsState.map((item, i) => {
                    return (
                        <div
                            className={`flip-card clipped-border ${item.clicked ? "clicked" : ""}`}
                        >
                            <div className="flip-card-inner">
                                <div
                                    className="flip-card-front"
                                    onClick={() => handleClick(i)}
                                >
                                    <img src={item.img} className="clipped" />
                                    <i class={item.icon} />
                                </div>
                                <div
                                    className={`flip-card-back ${item.target}`}
                                    onClick={() => handleClick(i)}
                                >
                                    <h1>{item.title}</h1>
                                    <p className="description">{item.description}</p>
                                    <p className="media-link"><a href={item.url} target="_blank">
                                        <i class="fa-solid fa-arrow-up-right-from-square"></i>
                                        Ir
                                    </a></p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div class="shadow" />
        </div>
    );
};


export default Hexagons;